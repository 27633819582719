import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

const Aviator = () => {
    const [data , setData] = useState();
    const history = useHistory();

    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }

    let access_token = localStorage.getItem("token");

   

    useEffect(() => {
        let access_token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${access_token}`,
        };
        axios
          .get(baseUrl + `me`, { headers })
          .then((res) => {
            setData(res.data);
          })
          .catch((e) => {
            if (e.response.status == 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("token");
              window.location.reload();
              history.push("/login");
            }
          });
      }, []);

    return (
<>
              <Header user={data}/>
<iframe src={`https://aviator.skillludo.com?token=${access_token}`}></iframe>
</>
    );
};

export default Aviator