import React, { useEffect, useState, useRef } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import socket from '../Components/socket'
import '../css/landing.css'
import Rightcontainer from '../Components/Rightcontainer'
import Downloadbutton from '../Components/Downloadbutton'
import io from '../Components/socket'
import Header from '../Components/Header'
import { Token } from '@mui/icons-material'

export default function Landing ({ userAllData }) {
  const [open, setOpen] = useState(false)
  // const [userAllData, setUserAllData] = useState()

  const addsetting = localStorage.getItem('sitSetting')
  const addCaseSetting = JSON.parse(addsetting)
  // console.log(addCaseSetting);

  // const role = async () => {
  //   const access_token = localStorage.getItem('token')
  //   io.emit('getprofile', access_token)
  //   io.on('setProfile', data => {
  //     // setUserAllData(data)
  //   })
  // }

  useEffect(() => {
    // role()
  }, [])


  

  return (
    <>
      <div className='leftContainer mb_space'>
        <div className='pt-3 mt-5   Orher_page_main_section'>
          <div className='main-area'>
            <div className='gameCard pt-2'>
              <div className='alrt_msg_div'>
                <h5 className=''>
                  {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                    ? ''
                    : JSON.parse(localStorage.getItem('sitSetting'))
                        ?.site_message}
                </h5>
              </div>
            </div>

             {JSON.parse(localStorage.getItem('udata'))?.verified === "unverified" ?  <div className='collapseCard-container'>
            <div className='collapseCard'>
              <Link to='/Profile'  style={{ textDecoration: 'none' }}>
                <div
                  className='collapseCard-body'
                  style={{
                    border:'1px solid red',
                    height: '50px',
                    opacity: 1,
                    transition: 'height 0.3s ease 0s, opacity 0.3s ease 0s'
                  }}
                >
                  <div className='collapseCard-text text-dark'>
                    <span className=' text-danger'>
                     Please Complete Your Kyc
                    </span>
                  </div>
                </div>
              </Link>

             
            </div>
          </div> :""}
         

            <section className='games-section'>
              <div className='d-flex align-items-center justify-content-between mt-3 mb-0'>
                
                <h3 className='tour_heading'>Our Tournaments</h3>
               
              </div>
              <div className='games-section-headline mt-0 mb-1'>
                <div className='games-window '>
                 

                <>
                    {/* =================Ludo Classic================ */}

                <Link
                  className='gameCard-container'
                  to={`/Homepage/ludoClassicManual`}
                >
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ LIVE
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/ludo-classic.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link>

                {/* =================Ludo popular================ */}

                {/* <Link
                  className='gameCard-container'
                  to={`#`}
                >
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ Comming Soon
                  </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/ludo-popular.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link> */}
                   {/* =================Ludo Lite Classic================ */}
                
                <Link className='gameCard-container'
                  to={`#`}>
                  {/* //  to={`/aviator`}>  */}
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ Comming Soon
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/aviator.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link>
                <Link className='gameCard-container mt-3'
                  to={`#`}>
                   {/* to={`/colorPredication`}> */}
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ Comming Soon
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/Skill Ludo.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link>
                <Link className='gameCard-container mt-3'
                  to={`#`}>
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ coming soon
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/Rummy-1.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link>
                {/* =================Ludo Ulta================ */}
                {/* <Link className='gameCard-container' to={`/Homepage/Ludo%20Ulta`}>
                  // <span className='d-none blink text-danger d-block text-right game_status_live'>
                  //   ◉ LIVE
                  // </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/ulta-ludo.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link> */}
                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Ulta`} > */}

                {/* =================Ludo Snake================ */}
                {/* <Link className='gameCard-container' to={`/Homepage/Ludo%20Snake`}>
                  // <span className='d-none blink text-danger d-block text-right game_status_live'>
                  //   ◉ LIVE
                  // </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/ulta-snake.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link> */}

                {/* =================Ludo skillludo================ */}
                {/* <Link className='gameCard-container' to={``}>
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ Comming Soon
                  </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/skillludoludo.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link> */}
                  
                </>

                
                </div>
              </div>
            </section>

            <div className='downloadButton'>
              <Downloadbutton />
            </div>
          </div>
        </div>
      </div>
      <div className='rightContainer'>
        <Rightcontainer />
      </div>
    </>
  )
}
