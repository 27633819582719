import React, { useState, useEffect, useRef } from "react";
import css from "../css/gamehis.module.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Invoice from "./Invoice";
import { AiOutlineDownload } from "react-icons/ai";
import "./invoice.css";
import socket from "../Components/socket";
import { BsFillEyeFill } from "react-icons/bs";

const Transactionhistory = () => {

   // BASE URL
 
   const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
   const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
   const nodeMode = process.env.NODE_ENV;
   if (nodeMode === "development") {
 
     var baseUrl = beckendLocalApiUrl;
   } else {
     baseUrl = beckendLiveApiUrl;
 }
 // +++++++++++++++++++++++++++++ 
  let printRef = useRef(null);

  const [show, setShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

 

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setInvoiceData(e);
  };

  const [user, setUser] = useState("");

  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    socket.emit("getprofile");

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl+`me`, { headers })
      .then((res) => {
        setUser(res.data);

        //Allgames(res.data._id)
        Allgames(res.data._id, pageNumber, limit);
        // window.location.reload()
      });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
   
  };

  // ALL APIS HERE

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    setIsLoading(true);
    await axios
      .get(
        baseUrl+`temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then((res) => {
        setGame(res.data.ress);
        {
          // console.log(res.data.ress);
        }

        setNumberOfPages(res.data.totalPages);
        setIsLoading(false);
      })
      .catch((e) => {});
  };

  

  useEffect(() => {
    role();
    //eslint-disable-next-line
  }, [pageNumber, limit]);

  const [Withdrawal, setWithdrawal] = useState();
  const [showBottom, setShowBottom] = useState(false);

  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = (data) => {
    setShowBottom(true);
    setWithdrawal(data);
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };
  return (
    <>
      <div>
        <div className="Orher_page_main_section mt-3">
         

          {/* game-cards */}

          {isLoading ? (
            <div className="text-center">
              {cardData && cardData.length === 0 ? (
                
                <div className="mt-2">
                  
                  <h3 className="font-weight-bold">No transaction History</h3>
                  <p className="text-muted">
                    You have not made any transaction yet.
                  </p>
                </div>
              ) : (
                <div
                  className="loaderReturn text-center"
                  style={{ zIndex: "99" }}
                >
                  <img
                    src={"/images/LandingPage_img/loader1.gif"}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>
          ) : (
            cardData &&
            cardData.length === 0 && (
              <div className="mt-2 text-center">
                <h3 className="font-weight-bold">No transaction History</h3>
                <p className="text-muted">
                  You have not made any transaction yet.
                </p>
              </div>
            )
          )}
        </div>
        {/* new code history  */}

        <div className="transaction_main_section_modal">
  {cardData &&
    cardData
      .filter((card) => card?.Req_type === "penalty") // Only include penalty transactions
      .map((card) => {
        var id = card._id.toString(),
          ctr = 0;
        var timestamp = id.slice(ctr, (ctr += 8));
        var machineID = id.slice(ctr, (ctr += 6));
        var processID = id.slice(ctr, (ctr += 4));
        var counter = id.slice(ctr, (ctr += 6));

        var counterid = parseInt(timestamp, 16);

        var titleMsg = "Penalty"; // Always show "Penalty"
        var signIcon = <div className="text-danger">(-)</div>;

        return (
          <div className="other_pages_card_main mt-3">
            <div>
              <h3>{titleMsg}</h3>
              <h3>
                Order ID:{" "}
                <span className="dark_light_color">{counterid}</span>
              </h3>
              <p>
                Status:
                <span className="dark_light_color">
                  {card?.status === "none" ||
                  (card?.status === "Pending" &&
                    card?.Req_type === "withdraw")
                    ? "Processing"
                    : card?.status === "Pending" &&
                      card?.Req_type === "deposit"
                    ? "Cancelled"
                    : card?.status}
                </span>
              </p>
              <p className="dander_text transaction_msg">
                {card?.txn_msg ? card?.txn_msg : ""}
              </p>
            </div>

            <div className="transaction_right_side_main">
              <div className="card_rupee d-flex float-right">
                {signIcon}
                <picture className="">
                  <span>₹</span>
                </picture>
                <span className="pl-1">{card?.amount}</span>
              </div>
              <div>
                {card?.closing_balance && (
                  <div
                    className={`${css.games_section_headline}`}
                    style={{
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Closing balance: {card?.closing_balance}
                  </div>
                )}
              </div>
              <div className="card_date">
                <p>{dateFormat(card?.createdAt).split(",")[0]}</p>
                <p>{dateFormat(card?.createdAt).split(",")[1]}</p>
              </div>
            </div>
          </div>
        );
      })}
</div>

        <div>
          
        </div>
      </div>

      {/* <Modal
        show={showBottom}
        onHide={handleCloseBottom}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="transaction_details_modal_body">
          <div>
            <h1>Withdrawl Details</h1>
          </div>
{Withdrawal?.Withdraw_type === "banktransfer" ? ( <div className="bank_detail">
            <div className="d_flex">
              <h2>Payment Details:</h2>
              <h2>Bank Transfer</h2>
            </div>
            <div className="d_flex">
              <h2>Account Number:</h2>
              <h2>125263456845</h2>
            </div>
            <div className="d_flex">
              <h2>Ifce Code :</h2>
              <h2>SBIN0003554</h2>
            </div>
            <div className="d_flex">
              <h2>UTR:</h2>
              <h2>{Withdrawal?.referenceId}</h2>
            </div>
            
          </div>) : Withdrawal?.Withdraw_type === "Upi" ? (<div className="Upi_Detail">
            <div className="d_flex">
              <h2>Payment Details:</h2>
              <h2>Upi Transfer</h2>
            </div>
            <div className="d_flex">
              <h2>UPI ID:</h2>
              <h2>{Withdrawal?.upi_no}</h2>
            </div>
          </div>):""}
         

          
            <div className="d_flex">
              <h2>Status:</h2>
              <h2 className="transaction_Success T_success T_pending T_process">
                {Withdrawal?.status}
              </h2>
            </div>
            <div className="d_flex">
              <h2>Request Time:</h2>
              <h2>{dateFormat(Withdrawal?.createdAt).split(",")[0]}</h2>
              <h2>{dateFormat(Withdrawal?.createdAt).split(",")[1]}</h2>
            </div>
            <div className="d_flex">
              <h2>Processed Time:</h2>

              <h2>{dateFormat(Withdrawal?.updatedAt).split(",")[0]}</h2>
              <h2>{dateFormat(Withdrawal?.updatedAt).split(",")[1]}</h2>
            </div>
          <div className="transaction_footer">
            <p>
              नोट:- यदि आपका Withdrawl स्टेटस 'Pending' दिखा रहा है तो वो बैंक
              की तरफ़ से Pending है हमारे तरफ़ से भेज दिया गया है। इसलिये कृपया
              1-5 दिन का इंतज़ार करे । पेंडिंग हटने के बाद भी यदि आपके पैसे नहीं
              आते है तो Support पेज में से हमें संपर्क करें ।
            </p>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* bottom modal  */}
    </>
  );
};

export default Transactionhistory;
