import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
} from "react-router-dom";
import Homepage from "./uiComponents/Homepage";
import Landing from "./uiComponents/Landing";
import userLogin from "./uiComponents/Login";
import Mywallet from "./uiComponents/Mywallet";
import Addcase from "./uiComponents/Addcase";
import Pay from "./uiComponents/Pay";
import Withdrawopt from "./uiComponents/Withdrawopt";
import Profile1 from "./uiComponents/Profile1";
import ViewGame1 from "./uiComponents/ViewGame1";
import Gamehistory from "./uiComponents/Gamehistory";
import "animate.css";
import axios from "axios";
import ViewListIcon from "@mui/icons-material/ViewList";

import { GiHamburgerMenu } from "react-icons/gi";
import "./uiComponents/Chat.css";
import CancelIcon from "@mui/icons-material/Cancel";
import Transactionhistory from "./uiComponents/Transactionhistory";
import Referralhis from "./uiComponents/Referralhis";
import Refer from "./uiComponents/Refer";
import Notification from "./uiComponents/Notification";
import Support from "./uiComponents/Support";

import Kyc2 from "./uiComponents/Kyc2";

import RefundPolicy from "./uiComponents/RefundPolicy";
import terms_condition from "./uiComponents/terms_condition";
import PrivacyPolicy from "./uiComponents/PrivacyPolicy";
import Gamerules from "./uiComponents/Gamerules";
import ResponsibleGaming from "./uiComponents/ResponsibleGaming";
import Return from "./uiComponents/Return";
import Notify from "./uiComponents/Notify";
import Header from "./Components/Header";
import Rightcontainer from "./Components/Rightcontainer";

import Redeem from "./uiComponents/Redeem";
import AboutUs from "./uiComponents/AboutUs";
import socket from "./Components/socket";
import Invoice from "./uiComponents/Invoice";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Win from "./uiComponents/ColorePredication"

import {
  firebaseApp,
  getTokens,
  onMessageListener,
} from "../services/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Chat from "./uiComponents/Chat";
import Maintenence from "./uiComponents/Maintenence";
import chatImage from "../assets/images/chatbutton.png";

import {
  Widget,
  addResponseMessage,
  addLinkSnippet,
  customLauncherButton,
  addUserMessage,
  renderCustomComponent,
  setQuickButtons,
  senderPlaceHolder,
  emojis,
  dropMessages,
  sendButtonAlt,
  handleQuickButtonClicked,
  toggleInputDisabled,
  deleteMessages,
} from "react-chat-widget";

import "react-chat-widget/lib/styles.css";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddIcon from "@mui/icons-material/Add";
import Newhistory from "./uiComponents/Newhistory";
import TDSPolicy from "./uiComponents/TDSPolicy";
import BankWithdrawal from "./uiComponents/BankWithdrawal";
import Terms from "./uiComponents/Terms";
import HomePageNew from "./uiComponents/HomePageNew";
import Shipping from "./uiComponents/Shipping";
import SabpaisaPaymentGateway from "./uiComponents/SabpaisaPaymentGateway";
import SabpaiseGateway from "./uiComponents/SabpaiseGateway";
import aviator from "./uiComponents/aviator";
import ColorePredication from "./uiComponents/ColorePredication";
import Aviator from "./uiComponents/aviator";
import Bonushistory from "./uiComponents/Bonushistory";

const App2 = () => {
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  // const [userAllData, setUserData] = useState([]);
  // console.log(userAllData,"dsffds")
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((response) => {
        localStorage.setItem('phone', response.data.Phone)
      })
      .catch(() => {});
  },[]);


  const currentPath = window.location.pathname;

  // +++++++++++++++++++++++++++++
  const [ticketList, SetTicketList] = useState([]);

  const [conversationData, setConversationData] = useState([]);
  const [current_ticket_id, setcurrent_ticket_id] = useState(null);
  const [ticket, SetTicket] = useState({});
  const [ticketGenerate, setTicketGenerate] = useState([]);
  const [isFileSelected, setIsFileSelected] = useState();
  // const [otpRequested, setOtpRequested] = useState(false);

  const access_token = localStorage.getItem("token");
  const [userData, setUser] = useState();
  // console.log(userData?._id)

  // ALL GAMES AND RUNNUNG GAMES API

  const [allplay, setallPlay] = useState([]);

  const Allgames = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + "challange/all", { headers })
      .then((res) => {
        setallPlay(res.data);
      })
      .catch((e) => {
        console.log("errror", e);
      });
  };

  useEffect(() => {}, []);

  // Ticket LIST API =================

  function TicketList() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(baseUrl + "chat/ticket-list?limit=40&page=1", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == false) {
          toast.error(result.msg);
        }
        SetTicketList(result.results);

        Conversation(result.results[0]);
        // console.log(result.totalRecord);
        if (result.results.length === 0) {
          var menu = document.querySelector(".othermenu_list");
          menu.style.display = "block";
        } else if (result.results.status === 3) {
          var menu = document.querySelector(".othermenu_list");
          menu.style.display = "block";
        }
      })
      .catch(function (response) {
        //handle error
        // console.log(response);
      });
  }

  // image
  const ImageUrl = "https://api.24x7mail.com/uploads/";
  const handleSendMessage = (msg) => {
    if (!ticket._id) {
      deleteMessages(2);
      toast.error("Please genrate a support ticket on click ➕ icon.");
    } else {
      if (msg !== "" || isFileSelected) {
        const newMessage = {
          text: msg,
          recipients: ticket.agent_id,
          // media: isFileSelected && ImageUrl + isFileSelected,
          media: isFileSelected ? ImageUrl + isFileSelected : null,

          chat_id: ticket._id,
        };

        socket.emit("create-msg", newMessage);

        setIsFileSelected(null);
      }
    }
  };

  //Conversation API =============================

  function Conversation(ticketobj) {
    dropMessages();
    setcurrent_ticket_id(ticketobj.id);
    // console.log(ticketobj);
    SetTicket(ticketobj);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      baseUrl + `chat/conversation/${ticketobj._id}`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        result.results.map((item) => {
          const textWithLineBreaks = item.text.replace(/<br\s*\/?>/gi, "\n");

          const tempElement = document.createElement("div");
          tempElement.innerHTML = textWithLineBreaks;
          const plainText = tempElement.textContent || tempElement.innerText;

          if (item.recipients == userData._id) {
            if (item.media) {
              addResponseMessage("![this is picture](" + item.media + ")");
            }
            addResponseMessage(plainText);
          } else {
            addUserMessage(plainText);
          }
        });
        setConversationData(result.results);
      })
      .catch((error) => console.log("error", error));
  }

  const [type, setType] = useState("game");

  const GenrateTicket = (setType) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "X-RapidAPI-Key",
      "8db2493e80mshb1cafd4623c1038p120069jsnd4b034238e1a"
    );
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    var urlencoded = new URLSearchParams();
    urlencoded.append("supportType", setType);
    // urlencoded.append("supportType", "transation");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };

    fetch(baseUrl + "chat/genrate-ticket", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        TicketList();
        Conversation();
        setTicketGenerate(result);
      })
      .catch((error) => console.log("error", error));
  };

  // Image url Api ==============================

  const generate_url = async (picture) => {
    const formData = new FormData();
    formData.append("media", picture);

    await fetch("https://api.24x7mail.com/url", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json.data[0], "json.data[0]");
        setIsFileSelected(`https://api.24x7mail.com/uploads/${json.data[0]}`);
        // setIsFileSelected(json.data[0])
        return json.data[0];
      });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    // TicketList();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // icon of ticket list  status
  function getStatusColor(status) {
    switch (status) {
      case 0:
        return "Blue";
      case 1:
        return "Green";
      case 3:
        return "Red";
      default:
        return "";
    }
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <h4 style={{ fontSize: "18px !important", padding: "1% 5%" }}>
          Support Chat History
        </h4>
        {ticketList?.map((text, index) => (
          <>
            <Divider style={{ width: "100%" }} />
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemText
                  onClick={() => {
                    Conversation(text);
                  }}
                  primary={text.ticket_id}
                  secondary={
                    <FiberManualRecordIcon
                      style={{ color: getStatusColor(text.status) }}
                    />
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
      <Divider />
    </Box>
  );

  const { pathname } = useLocation();

  const [isTokenFound, setTokenFound] = useState(null);
  const [notification, setNotification] = useState("");
  const [settinData, setSettingData] = useState(undefined);
  const [show, setShow] = useState(false);
  // console.log(notification)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // console.log("ok");
    const UserId = localStorage.getItem("userId");
    socket.on("websettingGet", (data) => {
      localStorage.setItem("sitSetting", JSON.stringify(data));
      setSettingData(data);
    });
    socket.emit("getgamelist", UserId);
    socket.on("setGamelist", (data) => {
      setallPlay(data);
    });

    socket.on("conversation", (data) => {
      setConversationData(data.results);
      console.log(data.results, "data");
    });
    socket.on("disconnect", (data) => {
      socket.emit("joinUser", access_token);
      // console.log(data)
    });
    socket.on("recive-msg", (msgs) => {
      addResponseMessage(msgs.text);
      conversationData.push(msgs);
    });
    socket.on("setProfile", (data) => {
      setUser(data);
      localStorage.setItem("userId", data?._id);
      localStorage.setItem("udata", JSON.stringify(data));

      // userId = data?._id;
    });
  }, [socket]);

  useEffect(() => {
    socket.emit("joinUser", access_token);
    socket.emit("websetting", "");
    socket.emit("getprofile", access_token);
  }, [socket]);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        console.log(payload);
        console.log(notification);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });

        toast.success(
          <div>
            <b>{payload.notification.title}</b>

            {payload.notification.body}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })
      .catch((err) => console.log("failed: ", err));
  }, [notification]);
  useEffect(() => {}, [notification]);
  function updatedToken() {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `updateToken?firebaseToken=${isTokenFound?.token}`, {
        headers,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const history = useHistory();

  let imagesrc = isFileSelected;

  let buttons = [
    {
      label: "Share Image",
      value: "",
    },
  ];

  setQuickButtons(buttons);

  const addImage = () => {
    return "<img src='" + imagesrc + "'>";
    // Now send the message throught the backend API
  };

  const handlefileButtonClicked = (e) => {
    document.getElementById("myFileInput").click();
  };

  const handleSubmit = (event) => {
    return false;
  };

  // useEffect(()=>{
  //   socket.on("liveUsers",(data) => {
  //     // console.log(data, "new user")
  //   })
  // },[])

  const handleFileSelect = async (event) => {
    const picture = event.target.files[0];
    const formData = new FormData();
    formData.append("media", picture);

    await fetch("https://api.24x7mail.com/url", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        addUserMessage(
          "![this is picture](" +
            `https://api.24x7mail.com/uploads/${json.data[0]}` +
            ")"
        );
        const newMessage = {
          text: "",
          recipients: ticket.agent_id,
          // media: isFileSelected && ImageUrl + isFileSelected,
          media: `https://api.24x7mail.com/uploads/${json.data[0]}`,
          chat_id: ticket._id,
        };

        socket.emit("create-msg", newMessage);
      });
  };

  const hideInputBoxStyle = {
    display: "none",
  };

  useEffect(() => {
    const chatWidget = document.querySelector(
      ".rcw-conversation-container > .rcw-sender"
    );
    if (chatWidget) {
      if (ticket.status === 3) {
        chatWidget.style.display = "none";
      } else if (ticket.status === 1) {
        chatWidget.style.display = "";
      }
    }
  }, [ticket.status]);

  // hide and show menu ticket
  function otherMenu() {
    var menu = document.querySelector(".othermenu_list");
    menu.style.display = "block";
  }
  function otherMenuhide() {
    var menu = document.querySelector(".othermenu_list");
    menu.style.display = "none";
  }


  // useEffect(() => {
  //   if (currentPath === "/aviator") {
  //     const handleKeydown = (e) => {
  //       // Check for Ctrl + R (or Command + R on Mac)
  //       if ((e.key === 'r' || e.key === 'R') && (e.ctrlKey || e.metaKey)) {
  //         e.preventDefault();
  //         console.log('Prevented refresh via Ctrl + R');
  //       }
  //     };

  //     // Attach the event listener
  //     window.addEventListener('keydown', handleKeydown);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //       window.removeEventListener('keydown', handleKeydown);
  //     };
  //   }
  // }, [currentPath]);



  useEffect(() => {
    if (currentPath === "/aviator") {
      
      // Prevent page reload or navigation by using the beforeunload event
      const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = '';  // Required for the confirmation dialog in most browsers
      };

      // Attach the beforeunload event
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Also prevent Ctrl + R or Cmd + R for desktop
      const handleKeydown = (e) => {
        if ((e.key === 'r' || e.key === 'R') && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          console.log('Prevented refresh via Ctrl + R');
        }
      };

      // Attach the keydown event
      window.addEventListener('keydown', handleKeydown);

      // Disable pull-to-refresh on mobile
      const disablePullToRefresh = (e) => {
        if (e.touches.length > 1) return; // Allow normal multi-touch events
        e.preventDefault();  // Prevent pull-to-refresh behavior
      };

      // Add touchmove listener to prevent pull-to-refresh
      window.addEventListener('touchmove', disablePullToRefresh, { passive: false });

      // Clean up event listeners when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('keydown', handleKeydown);
        window.removeEventListener('touchmove', disablePullToRefresh);
      };
    }
  }, [currentPath]);



  useEffect(() => {
    if (currentPath === "/aviator") {

      // Prevent page reload or navigation by using the beforeunload event
      const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = ''; // Required for the confirmation dialog in most browsers
      };

      // Attach the beforeunload event
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Disable pull-to-refresh on mobile devices
      const preventPullToRefresh = (e) => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;

        // Prevent pull-to-refresh if the user is at the top of the page
        if (scrollTop === 0) {
          e.preventDefault(); // Prevents pull-to-refresh
        }
      };

      // Add touchmove listener to prevent pull-to-refresh
      window.addEventListener('touchmove', preventPullToRefresh, { passive: false });

      // Clean up event listeners when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('touchmove', preventPullToRefresh);
      };
    }
  }, [currentPath]);


  return (
    <>
      {JSON.parse(localStorage.getItem("sitSetting")) == "undefined" ? (
        ""
      ) : JSON.parse(localStorage.getItem("sitSetting"))?.siteMaintenance ==
        true ? (
        <div>
          <Maintenence />
        </div>
      ) : (
        <>
          {JSON.parse(localStorage.getItem("sitSetting")) == "undefined" ? (
            ""
          ) : JSON.parse(localStorage.getItem("sitSetting"))?.isChatSupport ===
            false ? (
            <>
              <div className="App">
                <Widget
                  handleNewUserMessage={handleSendMessage}
                  handleSubmit={handleSubmit}
                  showFileUploader="true"
                  launcherOpenImg={chatImage}
                  profileAvatar={
                    JSON.parse(localStorage.getItem("sitSetting")) ==
                    "undefined"
                      ? ""
                      : baseUrl +
                        "" +
                        JSON.parse(localStorage.getItem("sitSetting"))?.Logo
                  }
                  title={
                    <div>
                      <div className="other_issue_popup_plus_btn_main">
                        <div className="">
                          {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <div className="other_issue_popup_plus_btn_1">
                                <Button onClick={toggleDrawer(anchor, true)}>
                                  <GiHamburgerMenu />
                                </Button>
                              </div>
                              <Drawer
                                style={{ height: "60% !important" }}
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                              >
                                {list(anchor)}
                              </Drawer>
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="chat_btn">
                          किसी भी समस्या लिए यहाँ दबाये👉
                        </div>
                        <div className="other_issue_popup_plus_bt">
                          <div className="other_issue_popup_plus_btn">
                            <Button onClick={otherMenu}>
                              <ControlPointIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="othermenu_list">
                        <div className="other_issue_popup_plus_btn_2  hidebtn ">
                          <Button onClick={otherMenuhide}>
                            <CancelIcon />
                          </Button>
                        </div>
                        <div className=" pb-3 other_issue_popup_plus_btn_inner">
                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Profile");
                              otherMenuhide();
                            }}
                          >
                            Issue in Profile
                          </Button>
                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Game");
                              otherMenuhide();
                            }}
                          >
                            Issue in Game
                          </Button>

                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Deposit");
                              otherMenuhide();
                            }}
                          >
                            Issue in Deposit
                          </Button>
                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Withdraw");
                              otherMenuhide();
                            }}
                          >
                            Issue in Withdraw
                          </Button>
                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Referral");
                              otherMenuhide();
                            }}
                          >
                            Issue Referral
                          </Button>
                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Penalty");
                              otherMenuhide();
                            }}
                          >
                            Issue in Penalty
                          </Button>
                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Bonus");
                              otherMenuhide();
                            }}
                          >
                            Issue in Bonus
                          </Button>
                          <Button
                            variant="contained"
                            className="popup_btns_commaon"
                            href="#"
                            onClick={() => {
                              GenrateTicket("Others");
                              otherMenuhide();
                            }}
                          >
                            Others
                          </Button>
                        </div>
                      </div>
                      skillludo
                    </div>
                  }
                  subtitle={
                    ticket.ticket_id
                      ? "Support Id " + ":" + ticket.ticket_id
                      : ""
                  }
                  senderPlaceHolder={
                    ticket.status === 3 ? " " : senderPlaceHolder
                  }
                  showBadge="true"
                  resizable="true"
                  emojis={ticket.status === 3 ? "rcw-picker-icon hidden" : true}
                  sendButtonAlt={ticket.status === 3 ? null : "Send"}
                  launcherOpenLabel="Open Support"
                  chatId="rcw-111"
                  autofocus="true"
                  handleToggle={TicketList}
                  className="chat-widget-custom"
                  handleQuickButtonClicked={(event) =>
                    handlefileButtonClicked(event)
                  }
                />


            
                <div className="support_samll_card_btn_type whatsap_btn">
                  {JSON.parse(localStorage.getItem("sitSetting")) ==
                  "undefined" ? (
                    ""
                  ) : JSON.parse(localStorage.getItem("sitSetting"))
                      ?.whatsappSupport ? (
                    <>
                      <a
                        href={
                          JSON.parse(localStorage.getItem("sitSetting")) ==
                          "undefined"
                            ? ""
                            : JSON.parse(localStorage.getItem("sitSetting"))
                                ?.whatsappSupport
                        }
                        target="_blank"
                      >
                        <img
                          width="50px"
                          src={process.env.PUBLIC_URL + "/images/whatsapp.png"}
                          alt=""
                        />
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <input
                type="file"
                id="myFileInput"
                onChange={handleFileSelect}
                style={{ display: "none" }}
                accept="image/*"
              />
            </>
          ) : (
            ""
          )}
          <div className="leftContainer">
            <div>
              <Header user={userData} />
              
              <div className="support_samll_card_btn_type whatsap_btn">
                  {JSON.parse(localStorage.getItem("sitSetting")) ==
                  "undefined" ? (
                    ""
                  ) : JSON.parse(localStorage.getItem("sitSetting"))
                      ?.whatsappSupport ? (
                    <>
                      <a
                        href={
                          JSON.parse(localStorage.getItem("sitSetting")) ==
                          "undefined"
                            ? ""
                            : JSON.parse(localStorage.getItem("sitSetting"))
                                ?.whatsappSupport
                        }
                        target="_blank"
                      >
                        <img
                          width="50px"
                          src={process.env.PUBLIC_URL + "/images/whatsapp.png"}
                          alt=""
                        />
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>
            </div>
          </div>

          {!access_token ? (
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/landing" component={Landing} />
              <Route path="/login/:referralcode?" component={userLogin} />

              <Route path="/RefundPolicy" component={RefundPolicy} />
              <Route path="/Shipping" component={Shipping} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/term-condition" component={terms_condition} />
              <Route path="/tds-policy" component={TDSPolicy} />
              <Route path="/about" component={AboutUs} />
              <Route path="/refund-policy" component={RefundPolicy} />
              <Route path="/Terms" component={Terms} />
              <Route path="/contact-us" component={Support} />
              <Route path="/Gamerules" component={Gamerules} />
              <Route path="/responsible-gaming" component={ResponsibleGaming} />

              <Redirect to="/login" />
            </Switch>
          ) : (
            <Switch>
              <Route
                path="/transaction-history"
                component={Transactionhistory}
              />
              <Route
                path="/bonus"
                component={Bonushistory}
              />

              <Route exact path="/Referral-history" component={Referralhis} />

              <Route
                path="/landing"
                render={() => <Landing userAllData={userData} />}
              />
              <Route exact path="/history" component={Newhistory} />

              <Route
                exact
                path="/Gamehistory"
                render={() => (
                  <Gamehistory
                    TicketList={TicketList}
                    Conversation={Conversation}
                  />
                )}
              />

              <Route
                exact
                path="/HomePage/:Game/:type?"
                render={() => (
                  <Homepage
                    // rungame={rungame}
                    homePlay={allplay}
                    setsocket={socket}
                    users={userData}
                  />
                )}
              />

              <Route
                exact
                path="/refer"
                render={() => <Refer user={userData} />}
              />
              <Route exact path="/Notification" component={Notification} />
              <Route exact path="/aviator" component={Aviator} />
              <Route exact path="/" component={Landing} />

              <Route
                path="/profile"
                render={() => <Profile1 profile1={userData} />}
              />
              <Route
                path="/viewgame1/:id"
                render={(props) => <ViewGame1 {...props} userData={userData} />}
              />

              <Route path="/addcase" render={() => <Addcase />} />

              <Route path="/Withdrawopt" render={() => <Withdrawopt />} />
              <Route path="/BankWithdrawal" render={() => <BankWithdrawal />} />
              <Route
                path="/wallet"
                render={() => <Mywallet user={userData} />}
              />

              <Route
                path="/support"
                render={() => <Support handleShow={handleShow} />}
              />

              <Route exact path="/landing/:id" component={Landing} />
              <Route path="/kyc2" render={() => <Kyc2 user={userData} />} />
              <Route path="/Rules" component={Gamerules} />
              <Route path="/RefundPolicy" component={RefundPolicy} />
              <Route path="/Shipping" component={Shipping} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/term-condition" component={terms_condition} />
              <Route path="/tds-policy" component={TDSPolicy} />

              <Route path="/return" render={() => <Return />} />
              <Route path="/notify" component={Notify} />

              <Route path="/Redeem" render={() => <Redeem />} />
              <Route path="/contact-us" component={Support} />
              <Route path="/refund-policy" component={RefundPolicy} />
              <Route path="/shipping-delivery-policy" component={Shipping} />
              <Route path="/Gamerules" component={Gamerules} />
              <Route path="/responsible-gaming" component={ResponsibleGaming} />
              <Route path="/about" component={AboutUs} />
              <Route path="/invoice" component={Invoice} />
              <Route path="/pay-status" component={Pay} />
              <Route path="/maint" component={Maintenence} />
              <Route path="/sabpaisaPaymentGateway" component={SabpaisaPaymentGateway} />
              <Route path="/sabpaisaGateway" component={SabpaiseGateway} />
             
              <Route exact path="/colorPredication" component={ColorePredication} />

              <Redirect to="/landing" />
            </Switch>
          )}
          <div className="rightContainer">
            <Rightcontainer />
          </div>

          {/* <h2>Install Demo</h2> */}

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* Same as */}
          <ToastContainer />
          <Modal
            show={show}
            onHide={handleClose}
            animation={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="p-0 m-0">
              <Chat />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};
export default App2;
