// import { width } from '@mui/system';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import '../Components/Component-css/Header.css'
import css from './Component-css/Nav.module.css'
import BottonMenu from '../uiComponents/BottonMenu'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { FiPlus } from 'react-icons/fi'
import '../Components/Component-css/Header.css'
import { IoIosAdd, IoMdArrowRoundBack } from "react-icons/io";
import Swal from 'sweetalert2';

const w3_close = () => {
  
  const width = document.getElementById('mySidebar').offsetWidth
  document.getElementById('mySidebar').style.left = `-${width}px`
  document.getElementById('sidebarOverlay').style.display = 'none'
}
const w3_open = () => {
  document.getElementById('mySidebar').style.left = '0'
  document.getElementById('sidebarOverlay').style.display = 'block'
}


const addsetting = localStorage.getItem('sitSetting')
const udata = localStorage.getItem('udata')
const addCaseSetting = JSON.parse(addsetting)


const Header = ({user}) => {
  // console.log(user)

   // BASE URL
 
   const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
   const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
   const nodeMode = process.env.NODE_ENV;
   if (nodeMode === "development") {
 
     var baseUrl = beckendLocalApiUrl;
   } else {
     baseUrl = beckendLiveApiUrl;
 }
 // +++++++++++++++++++++++++++++ 
  const [isdropdownVisible, setIsdropdownVisible] = useState(false)

  const toggleDiv = () => {
    setIsdropdownVisible(!isdropdownVisible)
  }

  const history = useHistory()

  let access_token = localStorage.getItem('token')
  access_token = localStorage.getItem('token')

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .post(
        baseUrl+`logout`,
        {
          headers: headers
        },
        { headers }
      )
      .then(res => {
        // setUser(res.data)
        localStorage.removeItem('token')
        localStorage.removeItem('phone')
        window.location.reload()
        history.push('/')
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('token')
          localStorage.removeItem('phone')
        }
      })
  }

const navigate = useHistory();


  const handleBackClick = (e) => {
    e.preventDefault(); // Stop default anchor action

    // SweetAlert pop-up
    Swal.fire({
      
      title: 'चेतावनी ',
      text: "अगर आपका गेम चल रहा हे और आप चलते गेम में बैक करते हो तो आप गेम हार जायेंगे | बैक जाने के लिए YES करे और गेम जारी रकने के लिए NO करे | ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        popup: 'custom-swal-popup', // Apply custom class to the SweetAlert popup
      },
    }).then((result) => {
      if (result.isConfirmed) {
        history.push('/');  // Redirect to home page
      }
      // If 'No', nothing happens and the user stays on the page
    });
  };



  //  Download App
  const [promptInstall, setPromptInstall] = useState(null)

  useEffect(() => {
    const handler = e => {
      e.preventDefault()
      setPromptInstall(e)
    }
    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('transitionend', handler)
  }, [])

  const onClick = evt => {
    evt.preventDefault()
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
  }
  const currentPath = window.location.pathname;


  useEffect(() => {
    if (currentPath === "/aviator") {
      const handleKeydown = (e) => {
        console.log(e)
        if ((e.key === 'r' || e.key === 'R') && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          console.log('refresh');
        }
      };

      // Attach the event listener
      document.addEventListener('keydown', handleKeydown);

      // Clean up the event listener on component unmount
      return () => {
        document.removeEventListener('keydown', handleKeydown);
      };
    }
  }, [currentPath]);

  return (
    <>
      <div>
        {
          currentPath === "/aviator" ? "" : 
        <div className='header_top_message'>
          <span>  {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                    ? ''
                    : JSON.parse(localStorage.getItem('sitSetting'))
                        ?.header_message}</span>
        </div>
        }
        {access_token ? (
          <React.Fragment>
            <div id='sidebarOverlay' onClick={w3_close}></div>
            <div
              className='w3-sidebar w3-bar-block'
              id='mySidebar'
              style={{ paddingBottom: '70px' }}
            >
         
             
                 <Link
              to={'/Profile'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                {user && user.avatar ? (
                  <img
                    width='30px'
                    height='30px'
                    src="/user.png"
                    alt='profile'
                    style={{ borderRadius: '50px' }}
                  />
                ) : (
                  <img src='../images/avatars/Avatar7.png' alt='profile' />
                )}
              </picture>
              <div style={{ marginLeft: '.5rem' }}>My Profile</div>
            </Link>

              <Link
              to={'/landing'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={process.env.PUBLIC_URL + '/images/Header/gamepad.png'}
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Play Game</div>
            </Link>
              
           
              <Link
              to={'/wallet'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={process.env.PUBLIC_URL + '/images/Header/wallet.png'}
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>My wallet</div>
            </Link>
            {/* <Link
              to={'/Gamehistory'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={
                    process.env.PUBLIC_URL + '/images/Header/gamesHistory.png'
                  }
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Game History</div>
            </Link> */}
            {/* <Link
              to='/transaction-history'
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={
                    process.env.PUBLIC_URL + '/tractions.jpeg'
                  }
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Transaction History</div>
            </Link> */}
            <Link
              to={'/refer'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={process.env.PUBLIC_URL + '/images/Header/refer-history.webp'}
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Refer and Earn</div>
            </Link>
            <Link
              to={'/history'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
              <img
                  alt='img'
                  src={
                    process.env.PUBLIC_URL + '/tractions.jpeg'
                  }
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>History</div>
            </Link>
            <Link
              to={'/Referral-history'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={
                    process.env.PUBLIC_URL + '../images/icons/policy.png'
                  }
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Refer History</div>
            </Link>
            <Link
              to={'/Notification'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={
                    process.env.PUBLIC_URL + '/images/Header/notifications.png'
                  }
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Notification</div>
            </Link>
            <Link
              to={'/support'}
              className='w3-bar-item w3-button'
              onClick={w3_close}
            >
              <picture className='icon'>
                <img
                  alt='img'
                  src={process.env.PUBLIC_URL + '/images/Header/support.png'}
                />
              </picture>
              <div style={{ marginLeft: '.5rem' }}>Support</div>
            </Link>
            <div
                className='w3-bar-item w3-button sidebar_link'
                onClick={onClick}
                style={{background:'#cbdcdf', fontWeight:"bold"}}
              >
                <picture className='icon'>
                  <img alt='img' src='../images/icons/download.png' />
                </picture>
                <h3 style={{fontWeight:"bold"}}>{isdropdownVisible ? ' App Download' : ' App Download'}</h3>
              </div>
             <div
                className='w3-bar-item w3-button sidebar_link'
                onClick={toggleDiv}
              >
                <picture className='icon'>
                  <img alt='img' src='../images/icons/policy.png' />
                </picture>
                <h3 style={{fontWeight:"bold"}}>{isdropdownVisible ? 'All Policy' : 'All Policy'}</h3>
              </div>
             
              {isdropdownVisible && (
                <ul className='dropdown_link' id='submenu'>
                  <li>
                    <Link to='/privacy-policy' onClick={w3_close}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to='/shipping-delivery-policy' onClick={w3_close}>
                    Shipping and Delivery Policy
                    </Link>
                  </li>
                  <li>
                    <Link to='/RefundPolicy' onClick={w3_close}>
                      Refund/Cancellation Policy
                    </Link>
                  </li>
                  <li>
                    <Link to='/term-condition' onClick={w3_close}>
                      Terms &amp; Condition
                    </Link>
                  </li>
                 
                  <li>
                    <Link to='/contact-us' onClick={w3_close}>
                      Contact
                    </Link>
                  </li>
                </ul>
              )}
             </div>


            <div className='w3-teal'>
              <div className='w3-container '>
                <div className={`${css.headerContainer} `}>
                {
                  currentPath == "/aviator" ?
                  <a href='/' onClick={handleBackClick}>
                 <IoMdArrowRoundBack />
               </a>
               
                 :

                  <button
                    className='w3-button w3-teal w3-xlarge float-left'
                    onClick={w3_open}
                    id='hambergar'
                  >
                    <picture className={`${css.sideNavIcon} mr-0`}>
                      <img
                        src={process.env.PUBLIC_URL + '/images/icons/menu1.png'}
                        className='snip-img'
                        alt=''
                      />
                    </picture>
                  </button>
                }
                  <Link to='/'>
                    <img
                      src='../skillludo.jpg'
                      className='snip-img'
                      alt='logos'
                      width='70px'
                      height='75px'
                    />
                  </Link>

                  <div className={`${css.menu_items}`}>
                    <Link className={`${css.box}`} to='/Addcase'>
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/rupee.png'
                          }
                          className='snip-img w-22'
                          alt=''
                          
                        />
                      </picture>
                      <div className='mt-1 ml-1'>
                        <div className={`${css.moneyBox_header}`}>Cash</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.Wallet_balance}
                        </div>
                      </div>
                      <picture className={`${css.moneyBox_add}`}>
                        {/* <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/LandingPage_img/addSign.png'
                          }
                          className='snip-img'
                          alt=''
                        /> */}
                      <IoIosAdd />
                      </picture>
                    </Link>
                    <Link
                      className={`${css.box} ml-2`}
                      to='/redeem/refer'
                      style={{ width: '80px' }}
                    >
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/LandingPage_img/notification-activity-reward.png'
                          }
                          className='snip-img'
                          alt=''
                        />
                      </picture>
                      <div className='mt-1 ml-1'>
                        <div className={`${css.moneyBox_header}`}>Earning</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.referral_wallet}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className='w3-teal '>
            <div className='w3-container '>
              <div className={`${css.headerContainer} justify-content-between`}>
                <Link to='/'>
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img
                     
                     src='../skillludo.jpg'
                     className='snip-img'
                     alt='logos'
                     width='70px'
                     height='70px'
                    />
                  </picture>
                </Link>

                <div className={`ml-5`}>
                  <Link type='button' className='login-btn' to='/login'>
                    LOGIN
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

       
      </div>
      <div className='bottom_menus_main'>
        <BottonMenu />
      </div>
    </>
  )
}

export default Header
