import React from "react";
import Header from "../Components/Header";

function Terms() {
  return (
    <>
      <div>
        <div className="leftContainer mb_space">
          <Header />
          <div className="mt-5 py-4 px-3">
            <div className="m-3">
              <h1 className="profile_headings">Privacy Policy</h1>
              <div>
                This Privacy Policy forms part of our Website’s Terms of Service
                and by accepting the Terms of Service and Privacy Policy on our
                registration page, you consent to share your sensitive personal
                data or personal information and are aware of the purpose of
                sharing such information. Please read this Privacy Policy
                carefully before using skillludoludo LUDO services. Please do not
                register if you do not wish to share with https://skillludoludo.com/
                (hereinafter referred to as “skillludoludo LUDO” or “we” or “us” or
                “our”) the mandatory personal information requested at the time
                of registration. Registration procedure cannot be completed
                until information sought in the non-optional fields is provided.
                Certain additional personal information may be requested by
                skillludoludo LUDO for permitting you access to any Cash Games hosted
                by skillludoludo LUDO. This Privacy policy applies to our services
                available under the domain and sub-domains of the Website
                (https://skillludoludo.com/). WHAT IS THE OBJECTIVE OF THIS PRIVACY
                POLICY? skillludoludo LUDO respects your privacy and assures you that
                any information provided by you to LUDO time is protected and
                shall be dealt with according to this Privacy Policy and the
                applicable laws. To avail yourself of the services offered on
                https://skillludoludo.com/ (hereinafter referred to as the “Website”),
                you may have to provide certain information to us. This Policy
                delineates the procedure followed by skillludoludo LUDO to gather,
                use, store, disclose and manage users’ personal data. skillludoludo
                LUDO collects personal data only for the purpose of verifying
                user accounts, maintaining the accounts of users, completing
                transactions initiated by users and for analyzing user behavior
                and requirements. Along with providing users with the most
                enjoyable online LUDO gaming experience, we also build a
                relationship of trust with our users. Our commitment to
                protecting user data is based on the principles of transparency,
                fairness, accuracy, data minimization, integrity and
                confidentiality. WHAT IS INCLUDED IN PERSONAL DATA? Users’
                personal data includes the following types of personal data or
                information: Sensitive Personal Data Account password •
                Financial information such as Bank Account, Credit Card, Debit
                Card or other payment instrument details Other Personal
                Information Name • Date of birth • Telephone number •
                Postal/Contact address • PAN (as applicable) • The IP address of
                your computer, browser type and language. • The date and the
                time of your accessing the site. • The address of the website
                that you may have used to link to our Website • Your photograph
                for the purpose of testimonials and other promotions. • Any
                other information that is defined as “sensitive personal data or
                information” in law. Users’ Consent Consent to the use of
                Sensitive Personal Data and Other Personal Information All users
                of skillludoludo LUDO consent to the use of their Sensitive Personal
                Data and Other Personal Information by skillludoludo LUDO for the
                purposes stated in this policy. We restrict access to personal
                user information to our employees, contractors and agents and
                only allow access to those who need to know such information in
                order to process it on our behalf. Users are entitled to
                withdraw consent to the use of their Sensitive Personal Data or
                Other Personal Information by emailing a specific request to
                help@skillludoludo.com Withdrawal of consent to use all or any part of
                Sensitive Personal Data and Other Personal Information may
                result in immediate withdrawal of any right provided to the user
                to use the services provided by skillludoludo LUDO Withdrawal of
                consent by users shall not restrict the right of skillludoludo LUDO to
                use Other Personal Information for the analysis of user data,
                subject to the condition that the Other Personal Information
                used in such analysis is not personally identifiable to any
                individual user. Users’ providing photographs to skillludoludo LUDO
                for marketing purposes gives skillludoludo LUDO irrevocable rights to
                use them and it is solely skillludoludo LUDO discretion to delete or
                not use them any further. skillludoludo LUDO may also use software
                applications for Website traffic analysis and to gather
                statistics, used for advertising and for determining the
                efficacy and popularity of skillludoludo LUDO and other such purposes.
                Consent to the use of cookies A cookie is a small text file that
                uniquely identifies your browser. You understand that when you
                visit the Website, cookies may be left in your computer. The
                cookies assigned by the servers of skillludoludo LUDO may be used to
                personalize your experience on the Website. Additionally,
                cookies may also be used for authentication, game management,
                data analysis and security purposes. Cookies may also be
                assigned by the advertisers of skillludoludo LUDO when you click on
                any of the advertisements, which may be displayed on any
                website, in which case such cookies are controlled by these
                advertisers and not skillludoludo LUDO. Consent to email communication
                When you register your email address with skillludoludo LUDO , you
                agree to receive email communication from skillludoludo LUDO ,
                entities specifically authorized by skillludoludo LUDO, and other
                users. You also agree and acknowledge that when you use our
                referral program for referring someone, skillludoludo LUDO may send
                emails to the person referred by you on your behalf and the
                email headers will carry your email address as the address from
                which such emails are sent. skillludoludo LUDO may also access and
                store such information relating to your contacts to send
                invitations and other promotions to them periodically. Consent
                to telephonic communication Any telephonic communication between
                you and our Customer Support staff may be recorded for the
                purposes of employee training and quality management, as well as
                the speedy resolution of your queries. DATA SECURITY We take
                appropriate security measures to protect user data from
                unauthorized access and its unauthorized alteration, disclosure
                or destruction. These measures include internal reviews of our
                data collection, storage and processing practices and security
                measures, including appropriate encryption and physical security
                measures to guard against unauthorized access to systems where
                we store users’ personal data. skillludoludo LUDO has a comprehensive
                information security program and information security policies
                that contain managerial, technical, operational and physical
                security control measures adopted by LUDO Winner for the
                protection of Sensitive Personal Data and Other Personal
                Information. Information gathered by skillludoludo LUDO is stored
                securely using several information security applications
                including firewalls. However, security is always relative and
                skillludoludo LUDO cannot guarantee that its security measures are
                absolute and cannot be breached. Data transmitted over the
                Internet is inherently exposed to security risks or threats. For
                instance, the security of information transmitted via chat or
                email might be compromised and such information may be used by
                others. Therefore, skillludoludo LUDO cannot guarantee any security
                for such information in the course of transmission through the
                internet infrastructure or any unsolicited disclosures made by
                any users using the services of the Website. When you register
                with skillludoludo LUDO, your account is protected by means of login
                information, including a username and a password, which is known
                only to you. Therefore, you should not provide your personal
                information to anyone whosoever: breach hereof constitutes
                violation of this Policy and may result in closure of your
                account in certain cases. If you become aware of or reasonably
                suspect any breach of security, including compromise of your
                login information, it is your responsibility to immediately
                notify skillludoludo LUDO. The Website may contain links to other
                websites. Such websites are governed by their own privacy
                policies, and skillludoludo LUDO does not exercise any control over
                them. It is your responsibility to read and understand the
                privacy policy of such websites when you visit them following
                links provided on the Website. You are advised to exercise
                caution in sharing any personal information with any third party
                that advertises on the Website: skillludoludo LUDO shall not be
                responsible for the information provided by you on or to third
                party websites. skillludoludo LUDO has a policy of not sharing any
                personally identifiable information with anyone other than the
                entities specifically authorized by skillludoludo LUDO, which may
                include advertisers and sponsors of skillludoludo LUDO. However,
                skillludoludo LUDO may use information like your name, photo, login ID
                and the state from where you are participating when announcing
                the results of any contests hosted on the Website. Such contests
                are further governed by terms and conditions, which shall be
                available on the Website as and when such contests are hosted on
                the Website. skillludoludo LUDO conducts periodic analysis and survey
                of the traffic to https://skillludoludo.com/ for market research and
                advertising purposes. skillludoludo LUDO reserves the right to share
                your registration information with skillludoludo LUDO -appointed
                market research and advertising companies or firms from time to
                time for the said purposes. skillludoludo LUDO may also use cumulative
                non-personal information for auditing and analysis purposes with
                the aim of improving its services. Privacy measures for minors
                Our Website and contests are meant only for persons aged 18 or
                above. If you are under 18 years of age, you are not permitted
                to use our Website. If you are a parent and/or guardian and
                believe that we may have inadvertently collected personal
                information from your child, you can notify us immediately by
                sending an email to help@skillludoludo.com skillludoludo LUDO may
                share Sensitive Personal Data and Other Personal Information if
                sharing of such information is necessary to comply with legal
                processes or governmental requests; • to enforce its Terms of
                Service and this Privacy Policy; • for prevention of fraud; •
                for issues involving information security, or • to protect: 1.
                your rights, 2. rights of skillludoludo LUDO, or 3. rights of the
                general public. LIMITATION OF LIABILITY skillludoludo LUDO confirms
                that this Privacy Policy is only a description of its operation
                regarding user information. This Privacy Policy is not intended
                to create, and does not create, any legal rights in your favor
                or in favor of any other person. skillludoludo LUDO reserves the right
                to change this Privacy Policy at any time without giving you
                prior notice. The liability of skillludoludo LUDO shall be limited to
                the removal of Sensitive Personal Data from the system of the
                Website and the removal of personally identifiable elements of
                the Other Personal Information. Notwithstanding anything to the
                contrary contained in this Policy and elsewhere, the aggregate
                liability of skillludoludo LUDO LIMITED for all claims for damages
                pursuant to the provisions of services on the Website, including
                claims in respect to the violation of this Policy, shall be
                limited to the aggregate skillludoludo LUDO amount of liability as
                provided in the Terms of Service. Your continued use of the
                Website constitutes your agreement to this Privacy Policy and
                any amendments thereof. All complaints related to personal data
                must be sent to the Grievance Officer  help@skillludoludo.com
              </div>
            </div>
            <div className="m-3">
              <h1 className="profile_headings" style={{color:"black"}}>Terms & Conditions</h1>

              <div>
                Products and/or services relating to the games (hereinafter
                collectively referred to as “Services”) provided to you and
                offered by SWAGSTAR GAMING PRIVATE LIMITED
Company and/or its
                affiliates, subsidiaries, associates and partners (hereinafter
                referred to as “We” or “Us” or “Our” Or “Company”) through its
                mobile application, namely, (hereinafter referred to as the
                “Application”) are subject to the Terms of Use and Service
                (hereinafter referred to as “Terms”) as specified herein. These
                Terms, as updated or modified from time to time, constitute an
                agreement between you and Company and shall govern the
                relationship between you and Company with respect to Services.
                You must read, understand and agree to, and be bound by, the
                Terms for accessing the Services. You must not access the
                Application or Services, in any manner, in case you do not
                expressly accept these Terms in its entirety. 1. AGREEMENT 1.1.
                These Terms along with privacy policy (hereinafter “Privacy
                Policy”) and the rules of the game-play, that is, Game Rules (as
                defined hereinafter) as may be notified and updated from time to
                time by the Company, constitutes a binding agreement between you
                and Company. You must read, understand and accept all the
                provisions envisaged under these Terms, Privacy Policy and Game
                Rule before accessing the Services and/or the Application in any
                manner. You understand and agree that Company reserves the right
                to modify or update these Terms, Privacy Policy and Game Rules
                at any time without needing to serve a prior notice upon you.
                Any such modification or update in the Terms, Privacy Policy and
                Game Rules shall, however, be intimated to you under the
                ‘Notice’ tab within the Application. You must accept the Terms,
                Privacy Policy and Game Rules so modified or updated to continue
                accessing the Services and Application. In case you do not
                accept the Terms, Privacy Policy and Game Rules so modified or
                updated, you must cease to access the Services and Application
                forthwith, and shall cancel your membership (as referred to
                hereinafter) and inform the Company immediately. If case you
                continue to access Services and Application after any such
                modification or update to these Terms, Privacy Policy and/or
                Game Rules, it shall be assumed that you have agreed to such
                modification or update, and be bound by the same. 2. MEMBERSHIP
                2.1. Any information provided by you to us, whether at the stage
                of registration or during any time subsequently, should be
                complete and truthful. 2.2. Your application towards
                registration as a member and a registered user of the Services
                shall be deemed as you having expressly agreed to these Terms,
                Privacy policy and Game Rules, as updated from time to time, and
                therefore, shall be bound by the same at all times. Your
                registration as a member and registered user of Services shall
                be subject to discretion of the Company, and the Company shall
                reserve the right to terminate your registration by serving an
                instant notice based on subjective judgement and reasons. 2.3.
                Without prejudice to the foregoing, the membership and access to
                Services shall be allowed to any person above the age of 18
                years, and the registration for membership shall be guided by
                the registration process stated in the Application. The Company
                reserves the right to verify the age of you as a user of
                Services by requesting for specified documentary proof in this
                behalf, and shall also reserve the right to impose ban in the
                event any documentary proof is found to be false. 2.4.
                Notwithstanding, any person requesting registration/membership
                shall, at all times, be bound by the applicable laws in the
                jurisdiction in which such person is situated. The Company shall
                be not be liable, in any manner, towards violation of any such
                applicable laws by the person requesting
                registration/membership. Neither Company, nor any other
                third-party providing any content on the Application, nor their
                related agents shall be responsible and liable for any direct or
                indirect, incidental or consequential injuries or damages that
                can arise as a result of the use of, or inability to use, Our
                Application by any person who is below the age of 18 years. 2.5.
                You acknowledge and agree that We are not obligated to
                cross-check or verify any of your information and hence, We do
                not take any responsibility for any consequences or outcomes
                that may result from your providing false, incorrect,
                incomplete, concealed or irrelevant information to Us.
                Therefore, you are required to provide Us with complete and
                authentic information to the best of your knowledge to play Our
                Games. Also, you should not be a politically exposed person or
                convicted of any crimes. You should not be on the UN sanction
                list/ work for UN sanction list. 2.6. You represent that you are
                not a politically exposed person. 2.7 You represent that you
                have not been convicted of any crime. 2.8 You represent that you
                are not on the UN Sanctions List or work for a company that is
                on the UN Sanctions list. 3. GAME VARIATIONS 3.1. The Company
                may offer various promotional games, practice games, cash games
                and other non-cash games on its Applications (hereinafter
                collectively referred as “Games”). Each of such Games shall be
                governed by specified set of rules of game-play notified by the
                Company (hereinafter referred to as “Game Rules”). The Game
                Rules can be found in the ‘Help’ section in the Application. You
                are required to play any of such Games solely in accordance with
                such notified Game Rules. 3.2. The Company offers the Games in
                two categories: I. Cash Game(s) are such Games that require
                participants to contribute make certain payments and/or to
                maintain a certain minimum cash balance in their account to
                participate. II. Non-cash Game(s) are such Games that does not
                require participants to contribute or make any payments or to
                maintain a certain minimum cash balance in their account to
                participate, that is to say, such Games are completely free to
                be played. 4. ACCOUNT CREATION AND OPERATIONS 4.1. You must
                register Yourself through the Application before accessing
                Services or playing the Game(s). The registration can be done by
                clicking on the “Register” section in the Application, and the
                registration can be proceeded with in accordance with the steps
                directed therein. In order to register Yourself, You must create
                your unique login name and password after submission of certain
                information as may be required therein. Further, you may be
                asked to submit certain documents for verification of your age
                and authenticity of other details as part of KYC (Know Your
                Client) process. Failure to furnish any such information or
                documents, or in case any information or document submitted by
                you is found to be false, Your registration along with your
                account associated with such registration may be suspended or
                terminated by Us, at Our discretion, without any prior notice.
                We shall not be liable towards, or be obligated towards, in any
                manner, for any loss incurred owing to account so suspended or
                terminated. 4.2. You acknowledge and agree that completing KYC
                process as required by the Company shall be a pre-condition to
                complete registration process on Our Application. Further, you
                agree that the Company may require you to re-verify your
                credentials by completing the KYC process on periodical basis.
                4.3. You must register Yourself only once with correct
                credentials, and shall not make more than one account on the
                Application, that is to say, You shall access the Services or
                play the Games only through one account. You are also prohibited
                from using account of someone else to access the Service or play
                the Games. In the event, You are found to be accessing the
                Services or playing the Game(s) through someone else’s account,
                You may barred by Us, for present and future, from accessing the
                Services or playing the Game(s), and also, We may, at Our
                discretion, terminate all the accounts associated with You or
                such other person without any prior notice. Further, you shall
                solely be responsible for any damage or loss incurred to any
                such third-person whose account may be used to access the
                Services and play the Games(s). 4.4. By registering yourself and
                by accessing the Services, You hereby agree to not sharing or
                distributing your password and/or other account information to
                any third-person, that is to say, only you shall be authorized
                to access your account and the Services. We shall not be
                responsible towards any loss or damage that may occur owing to
                such sharing of password and other account information. You
                shall solely be responsible towards all the activities on the
                Application through your Account. You understand and agree that
                We shall not incur any liability for information provided by you
                to anyone which may result in your account on the Application
                being exposed or misused by any other person or any other harm
                or loss being suffered by you. 4.5. You undertake not to
                impersonate another person or user of Our Application, or
                attempt to get a password or any other account information not
                belonging to you for accessing Our Application. We may initiate
                appropriate legal proceedings against you as well as notifying
                the relevant regulatory or law enforcement authorities where
                appropriate, apart from suspending or terminating your
                privileges for the use of the Application, in the event, we have
                reasonable grounds to believe that you have violated your
                undertaking herein in any manner. Any act in violation of this
                clause by you shall be deemed to be a material violation by us.
                5. EXCLUDED 5.1. The offering of Services by Us shall, at all
                time, be in complete compliance with the applicable laws. We
                understand that applicable laws in the Indian states of
                Meghalaya, Andhra Pradesh, Tamil Nadu, Telangana, Assam, Odisha,
                Nagaland and Sikkim (hereinafter “Excluded Territories”) may
                restrict or prohibit its residents from access to Services and
                playing the Cash Games, and such stated may impose penalties or
                punishments on finding any of its residents involved in
                accessing the Services and playing the Cash Games. Therefore,
                We, in compliance with such applicable laws, offer Our Cash
                Games and the Application in all Indian states except Excluded
                Territories. Accordingly, persons in Excluded Territories are
                explicitly prohibited form the accessing the Services and
                playing the Cash Games. We do not accept the registration as
                registered users on Application from persons in Excluded
                Territories for allowing to play Cash Games. Accessing the
                Services from an Excluded Territories by using IP or GPS masking
                software programs such as fake GPS, GPS Spoofer or others shall
                be breach of these Terms. In the event, any person in Excluded
                Territories registers by furnishing false information, We shall
                terminate the account of such residents forthwith upon knowing
                the fact of such information being false, and shall forfeit all
                the winnings or deposits with no obligation or liability
                whatsoever. You hereby expressly agree that it shall be sole
                responsibility of you to comply with the applicable state laws,
                and We shall not be liable in any manner towards breach of any
                such applicable state laws by you. 5.2. It may also be noted
                that the Services are being offered only in India and you shall
                only access the Services form India, while being in compliance
                with applicable laws. 5.3. The Company may, in compliance with
                the applicable laws, add more Indian states to the list of
                Excluded Territories, and as a result, you shall be bound by
                these Terms after such addition. 5.4 Games of skill are legal,
                as they are excluded from the ambit of Indian
                legislations including, the Public Act of 1867. 5.5 The
                game with the mobile app only in skill level knowledge which
                includes superior knowledge, training, attention, experience and
                adroitness of the player shall be classified as a game of skill
                5.6 By signing up on this Mobile App, you automatically agree
                that you are participating in a game of skill 6. PAYMENTS AND
                TAXES 6.1. You shall add cash in your gaming account in case you
                chose to play Cash Games on the Application. The aforementioned
                cash account shall constitute the follows: I. Deposits: Deposits
                are such payments that you deposit in your gaming account and
                participate in the Cash Game with the object of earning winnings
                that are more than the deposits so made. II. Winnings: Winnings
                are such amount that are won by you as winnings in the Cash Game
                You have participated. All the winnings are credited by Us in
                your gaming account. 6.2. While making any payment as deposits,
                you shall ensure that all the payments are made in Indian Rupees
                and the instrument used to make such payment shall belong to
                you. The payments are made through “Add Cash” section in the
                Application after you have logged in to your gaming account. The
                payments shall be subject to the limitation imposed by Us with
                respect to the skillludoludo LUDO cash that can be added to your
                gaming account. The ability to add cash to your gaming account
                shall be subject to the monthly “Add Cash” limits, which We can
                be set by Us with undertakings, indemnity, waiver and
                verification conditions as We deem appropriate in Our sole
                discretion. 6.3. The Company hereby declare, and User hereby
                agree, that any Deposit in Your account in accordance with the
                terms herein shall not constitute deposits in the Company with
                the meaning of prevailing company laws and other applicable
                laws. 6.4. Payments made through credit card, debit card,
                prepaid cash card and internet banking are processed by third
                party payment gateways. Similarly, payments made via other
                payment modes may require an authorization by the intermediary
                that processes the payments. We are not responsible for any
                delays or denials/rejections by such intermediaries or
                third-party gateways and processing of payments will be solely
                in terms of their policies and procedures, and We will not be in
                any way be responsible for the same. If case there are any
                issues in connection with adding cash to your account, a
                complaint can be sent to Us in accordance with the complaint
                procedure provided in the “Complaints and Disputes” section on
                the Application. You agree that in an event any of your payments
                is delayed or eventually declined for reasons beyond Our
                control, We shall not be held liable in any manner whatsoever.
                Once a payment/transaction is authorized, the money is credited
                into your account and is available to you to play Cash Games on
                Our Application. 6.5. We have the right to cancel a transaction
                at any point in time solely at Our discretion, in which case if
                the payment is successfully made, then the transaction will be
                reversed and the money credited back into your payment
                instrument. Players’ funds are held in trust by Us in specified
                bank accounts or in third party wallet liked with user’s gaming
                account with Us. We keep all its players’ funds unencumbered and
                your funds will be remitted to you in due course subject to
                these Terms and Game Rules applicable to the withdrawal of such
                funds. Funds held in your user account are held separately from
                Our corporate funds. Even in the extremely unlikely event of an
                insolvency proceeding, your claim on the deposits will be given
                preference over all other claims to the extent permissible by
                law. 6.6. You understand and acknowledge the fact that We are
                neither a bank nor a financial institution and We shall not be
                obligated towards payment of any interest on the amount
                accumulated in your gaming account. You acknowledge that credit
                to your gaming account by you either for participating in the
                Cash Games or is a result of cash prizes that you may win as a
                result of participation in the Cash Game. 6.7. Any withdrawals
                of payments from your gaming account shall be subject to
                deduction TDS as per Indian income tax laws and other rules and
                regulations of the Government of India. For the purpose of
                deduction of TDS, You agree to furnish Your PAN (Permanent
                Account Number) duly issued to You by the Income Tax Department,
                Government of India., as the case may be, to deduct TDS as per
                the rules and regulations of the Government of India. 6.8. You
                acknowledge and agree that We may charge service charges for
                Cash Games, after prior notice to you in this regard, which may
                vary depending on the nature of the Cash Game and are subject to
                change from time to time. Non-Cash Games are offered free on the
                Application, but may be subject to entry restrictions in some
                cases. Service charges charged by Us shall be inclusive of all
                applicable taxes, including GST (Goods and Service Tax). 6.9.
                Without prejudice to the foregoing, you are prohibited to use
                any credit card, debit card, prepaid cash card, internet banking
                or any other instrument not belonging to you to make Deposits
                and add money in your gaming account. You shall solely be
                responsible towards any loss incurred to the Company or any
                third-party owing to unauthorized use of any such instrument and
                accordingly, shall be obligated towards compensating the Company
                or any such third-party to make good such loss incurred.
                Additionally, the Company shall be at liberty to initiate and
                proceed with criminal prosecution against you in accordance with
                the applicable laws. Any act in violation of this clause by you
                shall be deemed to be a material violation by Us. 7. WITHDRAWAL
                POLICY 7.1. The Deposits made by you in your account can be
                withdrawn by you, by making a specific request in this behalf,
                subject to completion of KYC. However, Depositing and
                withdrawing money in your user account without playing any, or
                playing Cash Games inadequately, shall be inconsistent to Our
                fair play deposit and withdrawal policy. Such deposits and
                withdrawals in a consecutive or frequent manner shall be deemed
                as money laundering actions by you, which is not permitted on or
                through Our Application. Any violation in this regard, if proved
                upon Our investigation, shall be liable to penalty and ban from
                accessing the Services while confiscation of funds in credit in
                the user account, if any. Additionally, We may initiate
                appropriate legal action against you, and may, report the
                relevant authority with respect to your unlawful conduct. 7.2.
                You agree that any bonuses and promotional winnings are subject
                to withdrawal restrictions and can only be withdrawn after you
                have made at least one cash Deposit and thereafter, have played
                the minimum number of Cash Games required to be eligible for the
                bonus and promotional winnings. 7.3. We shall process a
                withdrawal request via NEFT- Electronic Transfer after KYC and
                withdrawal verification has been successfully completed. We
                shall always attempt to process the withdrawal through the mode
                of transfer selected by you. However, We reserve the right to
                modify the mode of withdrawal at Our discretion. Additionally,
                We reserve the right to reverse the amount to the
                payment/Deposit mode used to add cash in your user account. 7.4.
                We shall endeavor to process all withdrawal requests within 24
                hours of your making the request, and the bank may take another
                48 hours to deposit the money into your account. There could be
                delays due to the time required for verification and completing
                the withdrawal transaction. This may include National and Bank
                Holidays or disruptions of service. We shall not be liable to
                pay you any form of compensation for the reason of delays in
                remitting payments to you from your user account. 7.5. Without
                prejudice to the foregoing, you are permitted to play Games in
                fair manner in full compliance with the Game Rules, these Terms
                and other notification as may be made from time to time. 8.
                INTELLECTUAL PROPERTY The Company holds absolute ownership of
                all the tangible or intangible rights with respect to the
                Application and the Services offered, including all the Games,
                any promotional material and the technology involved, such right
                being referred to as the “Intellectual Property Rights”.
                Notwithstanding, any third-party content offered on the
                Application as part of Services shall be rights of such
                third-party. By allowing the access to the Services through
                Application and to play Games, We are only permitting you to
                play the Games in accordance with the prescribed Game Rules, and
                We are in no manner permitting you to use the Intellectual
                Property Rights including by way of copying, reproducing or
                transmitting any content, material or media. 9. TECHNICAL
                FAILURES 9.1. The Company uses information technology-based
                software to offer the Services and operate the Application.
                External internet networks and connections are used to connect
                the Application (operated through your mobile devices and
                hardware) to Our servers. We do not hold any liability towards
                any disruptions in such internet networks and connections
                including any errors, network disconnection or interruptions in
                communication and shall not be liable towards any loss incurred
                to you owing to such disruptions. Similarly, We shall not liable
                towards any loss arising out of any technical failures, server
                crashes, breakdowns, software defects, disruption or malfunction
                of Service owing to your fault or failure of your mobile device
                or hardware. 9.2. In the event of any technical failures, server
                crashes, breakdowns, software defects, disruption or malfunction
                of service attributed to Us while the Game(s) is in progress,
                You acknowledge and understand that We have no control over
                these factors. Further, you hereby expressly acknowledge that We
                shall not be responsible for any such interruption in Services/
                Game(s), and You take full responsibility for any risk of loss
                owing to Service interruptions for any such reason. 10.
                REPRESENTATION BY USER You, by accessing the Services and
                Application and agreeing to these Term, represent as follows:
                10.1. That you have capacity to enter into, execute and deliver
                this agreement, and to exercise your rights and perform your
                obligations as contemplated hereby, in accordance with the
                provisions of Indian Contact Act, 1872 and other applicable
                laws; 10.2. That this agreement constitutes the legal, valid and
                binding obligation on you, and shall be enforceable against you
                in accordance with these Terms, except as such enforceability
                may be limited by applicable bankruptcy, insolvency,
                re-organization, moratorium or similar laws affecting Our rights
                generally; 10.3. That consummation of the Terms contemplated
                hereby will not (i) conflict with or result in any breach or
                violation of any of the terms and conditions of, or constitute
                (or with notice or lapse of time or both constitute) a default
                under, any instrument, contract or other agreement to which you
                are a party or by you are bound; (ii) Violate any order,
                judgment or decree against, or binding upon, you; 10.4. That any
                information and documents reasonably required from you to comply
                with any applicable anti-money laundering or counter-terrorism
                financing laws including any applicable laws imposing know your
                customer or other identification checks or procedures that the
                Company may require to comply with in respect of this agreement
                shall be provided by You. 11. WARRANTY skillludoludo LUDO is committed
                to providing updated, accurate, complete and reliable
                information by publishing it on the Application. However, We
                disclaim any and all warranties, expressed and implied, with
                respect to the Services or Application or any of its content.
                All the information and content with respect to the Services and
                Application is provided to you on ‘as is’ basis, and you agree
                to access the Services and Application at your sole risk. We do
                not represent the suitability of the information published in
                documents or any other information that is updated on Our
                Application. We hereby solemnly disclaim and discard all
                warranties about any information as well as all implied
                warranties and conditions of Our merchantability, title and
                non-infringement, appropriateness to any particular purpose, and
                accuracy of Our Application and Services, software and Games. We
                do not give any warranty that the servers used to offer the
                Services and run the Application will run error-free,
                uninterrupted, or that they will not get affected by bugs,
                defects or viruses of any kind. Hence, We reserve the right to
                determine, at Our sole discretion, whether any issue at Our end
                is responsible for any error or disruption or malfunction. We
                may limit your access to Services or Application, if We opine
                that you are responsible for intentionally causing any
                malfunction or disruption. We shall not be liable for any
                assumed or potential wins in any unfinished Games on the
                Application. You may note that no person is authorized to extend
                or revise the warranty stated herein. 12. SOFTWARE USAGE It is
                strictly forbidden for anyone to use Our Services or Application
                for any commercial purposes. Our Application is meant only for
                accessing Services and playing Games solely for personal
                entertainment. You shall not attempt to manipulate, decode,
                modify, disassemble, decompile, reverse or re-engineer Our
                Application or its software in any manner. Please note that Use
                of Bots or Automatic Play is prohibited. Any attempt to use
                artificial intelligence that includes, but is not limited to,
                robots, in connection with our Application is strictly
                forbidden. You are authorized to access the Services solely
                through the Application while using the user interface contains
                therein. Any act in violation of this clause by you shall be
                deemed to be a material violation by Us. 13. FAIR PLAY POLICY We
                take the fair play of the Games very seriously. In order to
                prevent any form of fraud or unfair play in Our Games, all user
                actions including deposits and withdrawals, identity
                verification, and individual game hands are continuously
                monitored to ensure a safe, legal and fair environment for all
                Our users. Hence, as part of the fair play policy and to prevent
                any form of fraud and in-game collusion, all user actions are
                monitored and recorded as mentioned above. 13.1. Any detection
                of a breach of Our fair play policy, such as, but not limited
                to, fraudulent deposits under a stolen identity or collusion in
                play, shall result in prompt and serious action by Us against
                the suspected user, in the manner detailed herein below: 13.1.1.
                ANTI-CHEATING AND ANTI-COLLUSION 13.1.1.1. You undertake that
                you yourself will play in all Games for which you register/which
                you join and not use any form of external assistance to play.
                You shall not add any unauthorized components and not create nor
                use any cheats, exploits, bots, hacks or any other third-party
                software designed to modify the Application. You also undertake
                that you will not use any third-party software that intercepts,
                mines or in any way collects information from or through the
                Application or through any of Our Services. Any attempt to
                employ any such external assistance is strictly prohibited.
                13.1.1.2. Formation of teams by you and any other user(s) for
                collusion or any form of cheating in the Games on the
                Application is strictly prohibited. 13.1.1.3. Any act in
                violation of this clause 13.1.1 by you shall be deemed to be a
                material violation by Us. 13.1.2. Money Laundering You are
                prohibited from doing any activity on the Application that may
                be construed as money laundering, including, but not limited to,
                attempting to withdraw real money added through credit cards or
                deliberately losing money to a certain player(s). Any act in
                violation of this clause by you shall be deemed to be a material
                violation by Us. 13.1.3. Anti-Spamming Sending SPAM emails or
                any other form of unsolicited communication for obtaining
                registrations on the Application to benefit from any promotional
                program or for any other purpose is strictly prohibited. 13.1.4.
                Multiple IDs Your registration on the Application is restricted
                to a single user account, which will be used by you to avail
                yourself of the Services provided on the Application. You are
                prohibited from creating or using multiple user IDs for playing
                on the Application or using Our Services. 13.1.5. You may not
                create a login name or password, or upload/distribute
                /transmit/publish/ post content through or on the Application or
                through any service/facility including any messaging facility
                provided by the Application which: I. is libelous, defamatory,
                obscene, intimidating, invasive of privacy, abusive, illegal,
                harassing; II. contains expressions of hatred, expressions
                hurting religious sentiments, or expressions related to racial
                discrimination or pornography; III. is in any way objectionable
                or undesirable (whether or not unlawful); IV. is or can be
                construed as an incitement to commit a criminal offence; V.
                violates the rights of any person; VI. is aimed at soliciting
                donations or other form of help; VII. violates the intellectual
                property of any person and/or any legally formed entity; VIII.
                disparage in any manner to Us or any of Our subsidiaries,
                affiliates, licensors, associates, partners, sponsors, products,
                services, or Application; IX. promotes a competing service or
                product; X. violates any applicable laws. 13.1.6. In the event
                that We determine that the screen name chosen and created by you
                is indecent, objectionable, offensive or undesirable in any way,
                We shall notify You about it and you shall promptly provide Us
                with an alternate username so that We can change your existing
                username to the new name provided by you. If you fail to provide
                an alternate username, We reserve the right to either
                permanently suspend your user account or reactivate Your user
                account only after you have provided a different, acceptable
                username. 13.1.7. You shall not host, intercept, emulate or
                redirect proprietary communication protocols, if any, used by
                the Application, regardless of the method used, including
                protocol emulation, reverse engineering, nor modify the
                Application or any files that are part of the Application. Any
                act in violation of this clause by you shall be deemed to be a
                material violation by Us. 13.1.8. You shall not frame the
                Application. You may not post or upload editorial comments,
                commercial material or any information on the Application, alter
                or modify content on the Application, or remove, obliterate or
                obstruct any proprietary notices or labels. 13.1.9. You shall
                not use the Services on the Application for any commercial
                purposes including, but not limited to, use in a cyber café as a
                computer gaming center, network play over the Internet or
                through gaming networks or a connection to an unauthorized
                server that copies the gaming experience on the Application. Any
                act in violation of this clause by you shall be deemed to be a
                material violation by Us. 13.1.10. You shall not upload,
                distribute or publish through the Application any content that
                may contain viruses or computer contaminants (as defined in the
                Information Technology Act, 2000 or such other laws in force in
                India at the relevant time) which may interrupt, destroy, limit
                the functionality of or disrupt any software, hardware or other
                equipment belonging to Us or that aids in providing the services
                offered by Us. You shall not disseminate or upload viruses,
                programs, or software to the Application whether it is harmful
                to the Application or not. Any act in violation of this clause
                by you shall be deemed to be a material violation by Us.
                13.1.11. You shall not purchase, sell, trade, rent, lease,
                license, grant a security interest in, or transfer your user
                account, content, currency, points, standings, rankings,
                ratings, or any other attributes appearing in, originating from
                or associated with the Application. 13.1.12. Any form of
                fraudulent activity including attempting to use or using any
                other person’s credit card(s), debit cards, net-banking
                usernames, passwords, authorization codes, prepaid real money
                cards, and mobile phones for depositing money into your account
                is strictly prohibited. Any act in violation of this clause by
                you shall be deemed to be a material violation by Us. 13.1.13.
                Accessing or attempting to access Our Services through someone
                else’s user account is strictly prohibited. 13.1.14. Winnings,
                bonuses, bonus codes and prizes are unique to the player and are
                non transferable. In the event you attempt to transfer any
                winnings, bonuses or prizes, or share special bonus codes, the
                benefits of the same shall be forfeited. 13.1.15. The Company
                and its officers, directors, employees, consultants and agents
                and relatives of such persons (“Associated Persons”) have no
                access to the site’s systems, databases, and server-side
                operations and have no such information that allows any
                manipulation of the cards that are dealt and have no special
                access other than what is available to any user of the
                Application. They are also prohibited from playing, directly or
                indirectly, any real money games on the Application, other than
                any specific term of the engagement with Us from time to time.
                13.1.16. You shall not post any material or comment on any media
                available for public access that, in Our sole discretion, is
                defamatory or detrimental to Our business interests,
                notwithstanding the fact that such media is not owned or
                controlled by Us. In addition to any other action that We may
                take pursuant to the provision hereof, We reserve the right to
                remove any and all material or comments posted by You and
                restrict Your access to any media available for public access
                that is either controlled or moderated by Us, when in Our sole
                opinion, any such material or comments posted by You is
                defamatory or detrimental to Our business interests. 13.2.
                Breach and Consequences If We have evidence, through Our
                investigations or reasonable belief, of a breach of these Terms
                by you and/or that your continued access to Our Application is
                detrimental to the interests of Company or any other users or
                the general public, then We reserve the right to take any or all
                of the actions stated herein below at Our sole discretion:
                13.2.1. Permanently suspend your user account on Our
                Application; 13.2.2. Forfeit the amount of cash left in your
                account; 13.2.3. Demand and order damages for breach of these
                Terms and take required civil actions to recover damages;
                13.2.4. Initiate steps of prosecution for damages and violation
                that is equivalent to offences in law; 13.2.5. Put restrictions
                on the access of Our Games by users who are suspected in cases
                of cheating or colluding; 13.2.6. Bar you from playing or
                registering on Application in the future; 13.3. The action taken
                by Us will be solely due to your breach of Our Terms; the action
                shall be final and decisive that will be binding on you. Any
                action taken by Us will be without any prejudice to Our other
                rights and remedies that are mentioned and available in law or
                equity. 13.4. Without prejudice to the foregoing, you agree that
                you shall be obligated to compensate the Company for any loss or
                damage incurred to the Company as a result of breach of any of
                these Terms. Additionally, in case of any material violation, as
                referred to in these Terms, you shall be liable to compensate
                the Company with an amount of INR [100,000,00] or the amount of
                actual loss or damages, as may be quantified by Us, incurred to
                the Company, whichever is higher. 14. CONTENT AND CONTRIBUTION
                14.1. All the online content, materials and information on Our
                Application are collectively referred to as ‘Content’. It may
                include a wide range of online items such as images, designs,
                multimedia clips, graphics, logos, information, text content,
                games, software, animation, marks, hyperlinks, and pictures. The
                Content, whether or not belonging to Us, are thus protected and
                secured by applicable intellectual property rights.
                Additionally, We shall be free to record and use, in any manner
                whatsoever, all chat content, images, emails, and
                recommendations sent by any member or user on Our Application,
                as they appear on the Application and form part of the Content.
                14.2. It is informed that Our Application may contain
                information about third-parties and may comprise of hyperlinks
                within them. You shall use or access such third-party content or
                hyperlink at your sole risk. We shall not be responsible in any
                way for the implied warranty about the quality, integrity or
                accuracy of such third-party content. You expressly acknowledge
                that We shall not be responsible for any content belonging to
                any third-party, such third-party content appearing on Our
                Application shall not be deemed as endorsement towards such
                thirdparty content by Us. 14.3. You agree to not to post any
                content on the Application that does not belong to you, or with
                regards to which you do not have any rights or permission to
                post. You agree that you shall be solely be responsible towards
                any unlawful posting and shall be obligated to compensate Us for
                any loss or damage that We may have to bear on account of you
                posting any content unlawfully. 15. REFUND POLICY 15.1. The
                Company shall not be responsible for any loss resulting from the
                cancellation of any Games due to Service outages caused by the
                failures of Our service providers, computer viruses, natural
                disasters or any other reason that is beyond Our control. You
                acknowledge that you will not be entitled to any refund due to
                cancellation of games caused by factors that are beyond Our
                reasonable control. 15.2. In cases of accidental or erroneous
                cash credits to any party, user or company, and where Our
                reasonable independent investigation is not required in Our
                opinion, We may independently, or upon Your request, initiate
                the refund process within 2 (two) working days of such
                accidental or erroneous transaction. In cases where in Our
                opinion Our reasonable independent investigation is required, We
                shall process such refund that are proved to genuine upon Our
                such reasonable independent investigation that may take up to 2
                (two) weeks from the date of bringing such accidental or
                erroneous credit to the knowledge of the Company. Any such
                refund shall be made to the financial instrument/platform that
                was used to make the corresponding payments. 16. AMENDMENT In
                the event any provision or part-provision of these Terms is or
                becomes invalid, illegal or unenforceable, it shall be deemed
                modified to the skillludoludo LUDO extent necessary to make it valid,
                legal and enforceable. In case such modification is not
                possible, the relevant provision or part provision shall be
                deemed deleted. Any modification to or deletion of a provision
                or part provision shall not affect the validity and
                enforceability of the rest of these Terms. 17. LIMITATION OF
                LIABILITY You understand and agree that the Company shall be
                liable towards any direct or indirect damages, resulting from
                use of or inability to use the Service, even if We have been
                advised or become aware of the possibility of such damages or
                loss or that such loss was foreseeable. Such damages may include
                injury, claim, loss of data, loss of income, loss of profit or
                loss of opportunity, loss of or damage to property, damages for
                monetary loss, goodwill, use, data or other intangible loss
                (even if the Company has been advised of the possibility of such
                damages). You agree to indemnify Us against any claims in
                respect of any such matters. 18. ASSIGNMENT Your rights and
                obligations under this agreement is personal to you and shall
                not be assigned to any other party or person. The Company may,
                at its sole discretion, assign, transfer or novate this
                agreement or any of its rights and/or obligations under this
                agreement to any person, including their affiliate. 19.
                RELATIONSHIP BETWEEN PARTIES of the provisions of this agreement
                shall be deemed to constitute a partnership between the Company
                and user, and no party shall have any authority to bind the
                other parties otherwise than under this agreement or shall be
                deemed to be the agent of other in any way. 20. GOVERNING LAW
                AND DISPUTE RESOLUTION All disputes and differences arising from
                this agreement shall be referred to the Sole Arbitrator
                appointed by Company and User on mutual agreement, and such
                reference shall all time be governed by the provisions of the
                Arbitration and Conciliations Act, 1996. 21. CONTACT For any
                queries or suggestions, you may freely contact us at skillludoludo
                LUDO help@skillludoludo.com In the event you suspect any unauthorized
                use of your login credentials or any other information, you may
                inform us immediately requesting suitable actions. 22. COMPANY
                DETAILS Company Name: SWAGSTAR GAMING PRIVATE LIMITED
Company Email:
                help@skillludoludo.com Number: +91 7878848280 Address: OFFICE NO.- 433,
                UNNATI TOWER, JAIPUR, VIDHYADHAR NAGAR, JAIPUR-302039, RAJASTHAN
              </div>
            </div>
            <div className="m-3">
                <h1 className="profile_headings" style={{color:"black"}}>Cancellation & Refund Policy</h1>
                <div>
                
Refund, Return and Cancellation:

a) In the event, that there is an error i.e., technical glitch in the services provided by us, we may refund the entire amount, provided the reasons are genuine and proved after internal investigation by the company.

b) In case of exceptional circumstances such as recharge is successful but not received by the user/player, wherein the fault may lie with the payment gateway or from our side, we will refund within a reasonable amount of time.

c) Contact customer support via WhatsApp/e-mail/phone to raise your problem. Our support team will contact you immediately and resolve the problem in the shortest possible time.

d) In general, the refund will be settled in 3-5 working days, however, it might take several days depending on the complexity of the issues and after verification by our bank and payment gateway partner. We will try our best to create the best user experience for you.

e) Refund will not be processed if player/user violates game policy as mentioned in the “Terms of Service” and adopt various fraudulent means to exploit in the games.

To know more details about refund and cancellation please contact help@skillludoludo.com.
                </div>
                </div> 
            <div className="m-3">
            <h1 className="profile_headings">Withdrawal Policy</h1>
            <div>
              Withdrawal Policy skillludoludo LUDO has a total and unwavering
              commitment to the safety and security of player funds. Player
              balances are kept in segregated accounts and not used for
              operational expenses. This ensures that your funds are always
              available for you to cash out in accordance with our banking and
              security procedures. The minimum withdrawal request at a given
              point of time is Rs. 100 and the skillludoludo LUDO is Rs. 10,000. This
              is mandatory in order to encourage responsible play as well as to
              prevent unauthorized or illegal activity. skillludoludo LUDO gives away
              two free withdrawals in a month. All subsequent request for
              cash-out will be charged with 2% – 4% of the cash out amount as
              the service charges and the applicable TDS, which will be deducted
              from your account. If you do not have an adequate amount to charge
              the processing fees, your cash-outs will not be processed. You may
              resend a request in the succeeding month to avail free cash-out.
              In the event of game play for Cash Games, related Buy-in amount
              will be deducted from Player’s ‘Cash Balance’. If the game play
              Buy-in amount is more than the ‘Non-Withdrawable Balance’ amount
              in the Player’s Cash Balance, then the remaining amount will be
              deducted from Player’s ‘Withdrawable Balance’. In the event the
              Player decides to leave the game play table and is eligible for
              credit of game play amount, then the game play Buy-in amount will
              be credited back in the same proportion, to the Non-Withdrawable
              Balance and Withdrawable Balance, as it was deducted earlier. If
              the Player plays the Game and wins, the Winning amount will be
              reflected in the Player’s Withdrawable Balance and will be
              available for Withdrawal by the Player. Withdrawal without game
              play will generally not be entertained, however in some cases, 5%
              of the deposited amount will be charged to process the Withdrawal.
              If the account designated to receive your withdrawal has any
              issues, please cancel your withdrawal request and email us at
              help@skillludoludo.com. In order to protect against collusion and fraud,
              a 48-hour delay is imposed on cashing out from your last deposit.
              Therefore, please allow 48 hours from your last deposit before
              requesting your withdrawal. How to Make a Withdrawal Request? To
              make a withdrawal request, log in to your skillludoludo LUDO and click
              on Withdrawal tab. If your profile is complete and all mandatory
              documents submitted and verified, you will get the options for
              withdrawal, namely Bank Transfer. Modes of Withdrawal Bank
              Transfer Transfer is a withdrawal method that allows you to
              receive your winnings/funds by having them credited directly to
              your bank account. To make a withdrawal request via Bank Transfer,
              login to your skillludoludo LUDO account, click the ‘Withdrawal’ tab and
              then click on Bank Transfer. Fill in the amount you wish to
              withdraw, if this is your first withdrawal by Bank Transfer, in
              the next field, you will have to give your registered account
              number and fill in details of the bank in which you wish to
              transfer. Bank account no., Type of account, Bank Name, Branch
              details, IFSC Code and MIRC Code are some of the details you will
              need to submit to initiate Bank Transfer. Once these details are
              submitted, they cannot be edited, fill in the details carefully.
              If your bank details need to be changed, it can be done with the
              help of our support team, who will also verify the details. You
              will have to call and submit the required documents to make the
              changes. Note: Your Bank account needs to comply with the
              following conditions: The bank account must be in your name. The
              state mentioned in your Bank statement, must match the registered
              state in your skillludoludo LUDO Account. It may take up to 48 hours for
              skillludoludo LUDO to process your withdrawal request and another 2
              business days for funds to be credited to your designated bank
              account. There are no fees charged by skillludoludoLUDO.in, for using
              ‘Bank Transfer’ up to Two times in a month. However, an additional
              charge of 15.00 INR per transaction is charged by us for
              subsequent withdrawals in one month. Players are responsible for
              any fees charged by their bank or intermediary bank (if
              applicable) for receiving bank Transfers. For more information on
              your bank’s policy concerning fees for receiving Bank Transfers,
              please contact your bank directly. *All our successful withdrawals
              are processed on Daily basis except Sundays and Bank Holidays. For
              any clarifications please feel free to write to us at
              help@skillludoludo.com.
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
